.App {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.display {
	flex-basis: 30%;
	background-color: #202020;
	margin: 1em;
	margin-bottom: 0;
	padding: 1em;
	border: none;
	border-radius: 2em;
	display: flex;
	flex-direction: column;
	user-select: text;
}

.current {
	flex-basis: 65%;
	display: flex;
	align-items: flex-end;
	justify-content: right;
	font-size: 5em;
	transition: 0.2s flex-basis ease-in-out,
		0.2s font-size ease-in-out,
		0.2s color ease-in-out,
		0.2s padding-right ease-in-out;
}

.current-transition {
	flex-basis: 35%;
	font-size: 2em;
	color: #aaa;
	padding-right: 0.5em;
}

.cursor {
	animation-name: cursor;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	user-select: none;
}

@keyframes cursor {
	0% {
		color: #fff;
	}

	50% {
		color: #202020;
	}

	100% {
		color: #fff;
	}
}

.result {
	flex-basis: 35%;
	display: flex;
	align-items: flex-end;
	justify-content: right;
	font-size: 2em;
	padding-right: 0.5em;
	color: #aaa;
	transition: 0.2s flex-basis ease-in-out,
		0.2s font-size ease-in-out,
		0.2s color ease-in-out,
		0.2s padding-right ease-in-out;
}

.result-transition {
	flex-basis: 65%;
	font-size: 5em;
	color: #fff;
	padding-right: 0;
}

.keyboard {
	flex-basis: 70%;
	display: grid;
	gap: 0.5em;
	margin: 1em;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
}

.keyboard-button {
	background-color: #303030;
	border: none;
	border-radius: 0.75em;
	cursor: pointer;
	transition: 0.1s background-color ease-in-out, 0.0625s border-radius ease-in-out;
	font-size: 2em;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.keyboard-button:hover,
.keyboard-button:active {
	background-color: #484848;
}

.keyboard-button:active {
	border-radius: 1em;
}

.symbol {
	background-color: #1f1f1f;
	transition: 0.0625s background-color ease-in-out;
}

.symbol:hover,
.symbol:active {
	background-color: #303030;
}

.equals {
	background-color: #f55;
	transition: 0.0625s background-color ease-in-out;
}

.equals:hover,
.equals:active {
	background-color: #fa4848;
}

/* Buttons */

.button_Percent {
	grid-column: 2;
	grid-row: 1;
}

.button_Divide {
	grid-column: 4;
	grid-row: 1;
}

.button_Multiply {
	grid-column: 4;
	grid-row: 2;
}

.button_Minus {
	grid-column: 4;
	grid-row: 3;
}

.button_Plus {
	grid-column: 4;
	grid-row: 4;
}