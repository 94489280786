@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.roboto-light {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.roboto-regular {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.roboto-medium {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.roboto-bold {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.roboto-black {
	font-family: "Roboto", sans-serif;
	font-weight: 900;
	font-style: normal;
}

body {
	margin: 0;
	height: 100vh;
	width: 100vw;
	background-color: #010101;
	color: #fff;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

#root {
	height: 100%;
	width: 100%;
}